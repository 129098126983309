import { markRaw, defineAsyncComponent } from 'vue';

// Default async components
const OFilter = defineAsyncComponent(() => import('o365.vue.components.FieldFilter.vue'));
const OFilterDropdown = defineAsyncComponent(() => import('o365.vue.components.FilterDropdown.vue'));
const OTextEditor = defineAsyncComponent(async () => {
    const { OTextEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return OTextEditor;
});
const ONumberEditor = defineAsyncComponent(async () => {
    const { ONumberEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return ONumberEditor;
});
const OBitEditor = defineAsyncComponent(async () => {
    const { OBitEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return OBitEditor;
});
const ODateEditor = defineAsyncComponent(async () => {
    const { ODateEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return ODateEditor;
});

export default class BaseColumn {
    // General
    /** Unique identifier of the column */
    colId: string;
    /** Key of the value from data item */
    field: string;
    /** Enable editors for the column */
    editable: boolean;
    /** Hide column */
    hide: boolean;
    /** Side to which column will be pinned */
    pinned: 'left' | 'right';
    /** Data tpye of the column */
    type: 'string' | 'boolean' | 'bit' | 'number' | 'date' | 'datetime' | 'uniqueidentifier';

    // Order
    /** Current index of the column */
    order: number;
    /** Disables moving for the column */
    suppressMovable: boolean;

    // Sizing
    width: number;
    /** Disables column resizing */
    disableResizable: boolean;
    /** Min width of the column in px */
    minWidth: number;
    /** Max width of the column in px */
    maxWidth: number;
    /** Left postion in px */
    left: number;

    // Styling
    /** Class binding or function that returns the binding */
    cellClass: any | ((column: BaseColumn) => any);
    /** Style binding or function that returns the binding */
    cellStyle: any | ((column: BaseColumn) => any);
    /** Disable default classes for column cellls */
    disableDefaultClass: boolean;

    // Components
    /** Cell renderer component definition */
    cellRenderer: ICellRenderer | string;
    cellRendererParams: any;
    /** Cell editor component definition */
    cellEditor: ICellEditor | string;
    cellEditorParams: any;
    /** Header label component */
    headerLabel: IHeaderLabel;

    // Header
    /** Name to show in the header and other components wheere columns are used */
    headerName: string;
    /** Name for the tooltip in the header and other components wheere columns are used */
    headerTitle: string;
    /** Disables menu for the column header */
    disableMenu: boolean;

    // Navigation
    /** Suppress keyboard navigation for the column */
    suppressNavigable: boolean;
    /** Supress mouse click/drag selection for column */
    suppressSelection: boolean;
    /** Handle double click instead of edit mode attempt */
    dblclickHandler: Function;

    // Filter/sort
    /** Filter component used to render the filter cell */
    filter: IFilterComponent;
    /** Disable filtering for the column */
    disableFilter: boolean;
    /** Dropdown filter component used to render the filter dropdown */
    filterDropdown: IFilterComponent;
    /** Parameters for the filter */
    filterParams: IFilterParams;
    /** Disable sorting for the column */
    sortable: boolean;
    /** Sort direction */
    sort: 'asc' | 'desc';
    /** Sort order of the column */
    sortOrder: number;

    // Slots
    slots: Record<string, Function> = {};
    /** Data renderer slot */
    cellRendererSlot: Function;
    /** Data editor slot */
    cellEditorSlot: Function;
    /** Header label slot */
    headerTextSlot: Function;
    /** Filter component slot */
    filterSlot: Function;

    // Misc
    /** Format to use when formating values for display */
    format: string;
    /** Cell tooltip */
    cellTitle: string | ((row: any) => string);

    // Getters for data-object compatibility
    get name() {
        return this.field;
    }
    get caption() {
        return this.headerName;
    }

    constructor(options: IBaseColumnOptions) {
        if (options['column'] != null) { console.warn(options['column'], ': `column` prop is depricated, use colId instead'); }

        // General
        this.colId = options.colId ?? options['column'] ?? options.field;
        if (!this.colId) { throw new Error('Failed to create column object, colId is missing'); }

        this.field = options.field ?? options['column'] ?? options.colId;
        this.type = options.type;
        this.editable = options.editable ?? false;
        this.pinned = options.pinned ?? null;
        this.hide = options.hide ?? false;

        // Order
        this.order = options.order;
        this.suppressMovable = options.suppressMovable ?? false;

        // Sizing
        this.disableResizable = options.disableResizable ?? false;
        this.minWidth = typeof options.minWidth === 'string' ? parseInt(options.minWidth) : options.minWidth ?? 50;
        this.maxWidth = typeof options.maxWidth === 'string' ? parseInt(options.maxWidth) : options.maxWidth;
        this.width = typeof options.width === 'string' ? parseInt(options.width) : options.width ?? this.minWidth;
        if (this.width < this.minWidth) {
            this.minWidth = this.width;
        }
        this.left = options.left;

        // Styling
        this.cellClass = options.cellClass;
        this.cellStyle = options.cellStyle;
        this.disableDefaultClass = options.disableDefaultClass;

        // Components
        if (options.cellRenderer) {
            this.cellRenderer = markRaw(options.cellRenderer);
        }
        this.cellEditor = options.cellEditor;
        if (!this.cellEditor) {
            switch (options.type) {
                case "number":
                    this.cellEditor = ONumberEditor;
                    ONumberEditor.__asyncLoader();
                    break;
                case "date":
                case "datetime":
                    this.cellEditor = ODateEditor;
                    ODateEditor.__asyncLoader();
                    break;
                case "bit":
                case "boolean":
                    this.cellEditor = OBitEditor;
                    OBitEditor.__asyncLoader();
                    break;
                default:
                    this.cellEditor = OTextEditor;
                    OTextEditor.__asyncLoader();
            }
        }
        if (typeof this.cellEditor === 'object') {
            this.cellEditor = markRaw(this.cellEditor);
        }

        if (options.headerLabel) {
            this.headerLabel = markRaw(options.headerLabel);
        }
        this.cellRendererParams = options.cellRendererParams;
        this.cellEditorParams = options.cellEditorParams;

        // Header
        this.headerName = options.headerName ?? options.field ?? options.colId;
        this.headerTitle = options.headerTitle ?? this.headerName;
        this.disableMenu = options.disableMenu ?? false;

        // Navigation
        this.suppressNavigable = options.suppressNavigable ?? false;
        this.suppressSelection = options.suppressSelection ?? false;
        this.dblclickHandler = options.dblclickHandler;

        // Filter/sort
        this.filter = options.disableFilter
            ? false
            : options.filter ?? OFilter;
        this.filterDropdown = options.disableFilter
            ? false
            : options.filterDropdown ?? OFilterDropdown;
        if (typeof this.filter === 'object') {
            this.filter = markRaw(this.filter);
        }
        if (typeof this.filterDropdown === 'object') {
            this.filterDropdown = markRaw(this.filterDropdown);
        }
        this.filterParams = options.filterParams;
        this.sortable = options.sortable ?? false;
        this.sort = options.sort;
        this.sortOrder = options.sortOrder;

        // Slots
        this.slots = options.slots ?? {};
        this.cellRendererSlot = options.cellRendererSlot;
        this.cellEditorSlot = options.cellEditorSlot;
        this.headerTextSlot = options.headerTextSlot;
        this.filterSlot = options.filterSlot;

        // Misc
        this.format = options.format;
        this.cellTitle = options.cellTitle;
    }
}

/* TODO:
    filter typed interfaces    
*/

/*
stuff not included in base:
field: string;
type: string;
distinct: { columns: string[] };
lockPinned: boolean;
parentGroupId: string;
groupAggregate: string;
summaryAggregate: string;

dblclickHandler: ((e: MouseEvent, ...args: any) => void) | null;
*/

// Interfaces

interface IFilterComponent {
    props: {
        columnName: string;
        filterObject?: any;
    }
}

export interface IBaseColumnOptions {
    colId: string;
    field: string;
    editable: boolean;
    order: number;
    hide: boolean;
    width: string | number;
    pinned: 'left' | 'right';
    type: 'string' | 'boolean' | 'bit' | 'number' | 'date' | 'datetime' | 'uniqueidentifier';
    suppressMovable: boolean;
    suppressSelection: boolean;
    disableResizable: boolean;
    minWidth: number;
    maxWidth: number;
    left: number;
    cellClass: any | ((column: BaseColumn) => any);
    cellStyle: any | ((column: BaseColumn) => any);
    disableDefaultClass: boolean;
    cellRenderer: ICellRenderer | string;
    cellRendererParams: any;
    cellEditor: ICellEditor | string;
    cellEditorParams: any;
    headerName: string;
    headerTitle: string;
    disableMenu: boolean;
    suppressNavigable: boolean;
    filter: IFilterComponent;
    disableFilter: boolean;
    filterDropdown: IFilterComponent;
    filterParams: IFilterParams;
    sortable: boolean;
    sort: 'asc' | 'desc';
    sortOrder: number;
    format: string;
    cellTitle: string | ((row: any) => string);
    dblclickHandler: Function,
    cellRendererSlot: Function,
    cellEditorSlot: Function,
    headerTextSlot: Function,
    filterSlot: Function,
    headerLabel: IHeaderLabel,
    slots: Record<string, Function>,
};

export interface IFilterParams { };

export interface ICellEditor { };

export interface ICellRenderer { };

export interface IHeaderLabel { };